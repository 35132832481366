import { Stack } from '@mui/material'

import { CategoriesTree } from '../algolia/CategoriesTree'

export const Sidebar = () => {
  return (
    <Stack spacing={2} width={318}>
      <CategoriesTree dataCyPrefix="desktop" />
    </Stack>
  )
}
