import React from 'react'
import {
  useCurrentRefinements,
  useHierarchicalMenu,
  useInstantSearch,
} from 'react-instantsearch-core'

import { List, ListItem, ListItemButton, ListItemText, ListSubheader } from '@mui/material'
import type { RichTreeViewProps, TreeItem2Props, TreeViewBaseItem } from '@mui/x-tree-view'
import { RichTreeView, TreeItem2GroupTransition, TreeItem2Provider } from '@mui/x-tree-view'
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2'
import type { UseTreeItem2RootSlotOwnProps } from '@mui/x-tree-view/useTreeItem2/useTreeItem2.types'
import { keyBy } from 'lodash'
import { useDeepCompareMemo } from 'use-deep-compare'

import { NextLinkComposed } from '@/components/Link'
import { HIERARCHICAL_CATEGORIES, HIERARCHICAL_LEVELS_SEPARATOR } from '@/constants/algolia'
import { useCategories } from '@/providers/c/CategoriesProvider'
import { useRenderKey } from '@/providers/c/RenderKeyProvider'
import { useSearchBoxWithInternalState } from '@/providers/c/SearchBoxWithInternalStateProvider'
import { useLocalePathGenerator } from '@/utils/frontend/usePathGenerator'
import { useT } from '@/utils/frontend/useT'
import { isNotEmpty } from '@/utils/plain/isEmpty'
import { nonNullable } from '@/utils/plain/nonNullable'

type TreeViewItem = {
  id: string
  label: string
  href: string
}

const dataCyNodeTypeMap = {
  1: 'Category',
  2: 'Type',
  3: 'Family',
} as Record<number, string>

// more info: https://mui.com/x/react-tree-view/rich-tree-view/customization/#headless-api
const CustomTreeItem = (dataCyPrefix: string) =>
  React.forwardRef((props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) => {
    const { id, itemId, label, disabled, children, ...other } = props

    const { getRootProps, getContentProps, getLabelProps, getGroupTransitionProps, publicAPI } =
      useTreeItem2({
        id,
        itemId,
        children,
        label,
        disabled,
        rootRef: ref,
      })

    const { href } = publicAPI.getItem(itemId) as TreeViewItem

    const rootProps: UseTreeItem2RootSlotOwnProps = getRootProps(other)
    const contentProps = getContentProps()
    const labelProps = getLabelProps()
    const groupTransitionProps = getGroupTransitionProps()

    // if it has href, we assume it's a category level
    const listItemButtonProps = isNotEmpty(href) && {
      // use NextLinkComposed component for categories level
      component: NextLinkComposed,
      to: href,
      prefetch: false,
      shallow: true,
      scroll: false,
    }

    const nodeLevels = itemId?.split(HIERARCHICAL_LEVELS_SEPARATOR) ?? []
    const dataCyType = dataCyNodeTypeMap[nodeLevels.length]

    return (
      // @ts-ignore we will return a JSX.Element
      <TreeItem2Provider itemId={itemId}>
        <ListItem
          {...rootProps}
          data-cy={`${dataCyPrefix}-TreeItem-${dataCyType}-ListItem-${itemId}`}
        >
          <ListItemButton
            onMouseDown={contentProps.onMouseDown}
            selected={contentProps.status.selected}
            onClick={contentProps.onClick}
            {...listItemButtonProps}
            data-cy={`${dataCyPrefix}-TreeItem-${dataCyType}-ListItemButton-${itemId}`}
          >
            <ListItemText
              primary={labelProps.children}
              data-cy={`${dataCyPrefix}-TreeItem-${dataCyType}-ListItemText-${itemId}`}
            />
          </ListItemButton>
        </ListItem>
        {children && <TreeItem2GroupTransition {...groupTransitionProps} />}
      </TreeItem2Provider>
    )
  })

export const usePrepareCategoriesTree = () => {
  const { categories, allProductsCategory, isAllProducts } = useCategories()

  const { setIndexUiState } = useInstantSearch()

  const { getCategoryPagePath } = useLocalePathGenerator()
  const { forceNewRenderForFilterChips } = useRenderKey()
  const { clear } = useSearchBoxWithInternalState()

  // lvl0: categoryId, lvl1: categoryId > typeName, lvl2: categoryId > typeName > familyName
  const { items: hierarchicalCategories } = useHierarchicalMenu({
    attributes: HIERARCHICAL_CATEGORIES,
    limit: 99,
  })

  const hierarchicalCategoriesMapByValue = useDeepCompareMemo(
    () => keyBy(hierarchicalCategories, 'value'),
    [hierarchicalCategories],
  )

  const { items: currentRefinements } = useCurrentRefinements()

  const selectedTree = currentRefinements.find(
    (currentRefinement) => currentRefinement.attribute === HIERARCHICAL_CATEGORIES[0],
  )?.refinements?.[0]?.value as string

  const [refinedCategoryNodeId, refinedTypeValue] =
    selectedTree?.split(HIERARCHICAL_LEVELS_SEPARATOR) ?? []

  const refinedTypeNodeId = isNotEmpty(refinedTypeValue)
    ? [refinedCategoryNodeId, refinedTypeValue].join(HIERARCHICAL_LEVELS_SEPARATOR)
    : undefined

  const items: TreeViewBaseItem[] = categories.map((category) => {
    const hierarchicalCategory = hierarchicalCategoriesMapByValue[category.id]

    const nestedTypes: TreeViewBaseItem['children'] = hierarchicalCategory?.data?.map(
      (type) => ({
        id: type.value,
        label: type.label,
      }),
    )

    return {
      id: category.id,
      label: category.name,
      href: getCategoryPagePath({ categorySlug: category.slug }),
      children: nestedTypes,
    }
  })

  const itemsWithAllProductsCategory = [
    {
      id: allProductsCategory.id,
      label: allProductsCategory.name,
    },
    ...items,
  ]

  const onSelectedItemsChange: RichTreeViewProps<{}, false>['onSelectedItemsChange'] = (
    e,
    id,
  ) => {
    if (!id) return
    if (id === refinedCategoryNodeId) return

    const hierarchicalMenu =
      id === allProductsCategory.id ? undefined : { [HIERARCHICAL_CATEGORIES[0]]: [id] }

    clear()
    setIndexUiState((state) => ({
      ...state,
      refinementList: {},
      query: undefined,
      hierarchicalMenu,
    }))

    // we clear the rest of active filters
    forceNewRenderForFilterChips()
  }

  const selectedItem = (() => {
    if (isAllProducts) return allProductsCategory.id
    // we assume that if it's not a valid nodeId, then it's all products category
    return refinedTypeNodeId ?? refinedCategoryNodeId ?? allProductsCategory.id
  })()

  const expandedItems = [refinedTypeNodeId, refinedCategoryNodeId].filter(nonNullable)

  return {
    itemsWithAllProductsCategory,
    onSelectedItemsChange,
    selectedItem,
    expandedItems,
  }
}

export const CategoriesTree = ({ dataCyPrefix = 'desktop' }) => {
  const { t } = useT({ keyPrefix: 'catalog.filters' })

  const {
    itemsWithAllProductsCategory: items,
    onSelectedItemsChange,
    expandedItems,
    selectedItem,
  } = usePrepareCategoriesTree()

  return (
    <RichTreeView
      items={items}
      slots={{ item: CustomTreeItem(dataCyPrefix), root: List }}
      slotProps={{
        root: {
          // @ts-ignore these props are passed thought getRootProps(other) function inside CustomTreeItem
          dense: true,
          subheader: (
            <ListSubheader role="treeitem">{t('product-categories-title')}</ListSubheader>
          ),
          'data-cy': `${dataCyPrefix}-TreeView-categories`,
        },
        item: {
          // NOTE: for some reason, disablePadding is not applied, so we overwrite padding with sx
          // @ts-ignore these props are passed thought getRootProps(other) function inside CustomTreeItem
          disablePadding: true,
          sx: { p: 0 },
          // disableGutters: true,
        },
      }}
      selectedItems={selectedItem}
      expandedItems={expandedItems}
      onSelectedItemsChange={onSelectedItemsChange}
    />
  )
}
